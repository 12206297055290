import { Dispatch, SetStateAction, useState } from 'react';
import { Divider, makeStyles, Typography } from '@material-ui/core';
import { EventTimers } from 'types/analyticsTypes';
import { FlareInspectionSettings } from 'types/analytics/fire/FlareInspection';
import { useResizeDetector } from 'react-resize-detector';
import { JPGPreviewWithFetch } from 'views/StreamDetails/Preview/JPGPreviewWithFetch';
import { FireThresholds } from 'views/StreamDetails/Settings/Analytics/Fire/FireThresholds';
import FreeFormRectangles from 'components/KonvaShapes/FreeFormRectangle/FreeFormRectangles';
import { Trans } from 'react-i18next';
import { useStreamData } from 'context/providers/StreamDataProvider';
import { FlareAlarmingModeSettings } from '../../../FlareAlarmingModeSettings';
import { ReportingModeSettings } from '../../../ReportingModeSettings';

export type FlareInspectionProps = {
  settings: FlareInspectionSettings;
  setSettings: Dispatch<SetStateAction<FlareInspectionSettings>>;
  eventTimers: EventTimers;
  setEventTimers: Dispatch<SetStateAction<EventTimers>>;
};

export const FlareInspection = ({
                                  settings,
                                  setSettings,
                                  eventTimers,
                                  setEventTimers,
                                }: FlareInspectionProps) => {
  const classes = useStyles();
  const { width, height, ref } = useResizeDetector();
  const { vpaStreamId } = useStreamData();
  const [previewLoading, setPreviewLoading] = useState(true);

  const videSizeDetermined = width && height;

  return (
    <>
      <div className={classes.firstRow}>
        <div className={classes.previewWithZones}>
          <Typography variant="body2" className={classes.intro}>
            <Trans i18nKey="stream-settings.monitoring-zones-intro" />
          </Typography>
          {videSizeDetermined && !previewLoading && (
            <div className={classes.konvaShape}>
              <FreeFormRectangles
                width={width}
                height={height}
                settings={settings}
                setSettings={setSettings}
              />
            </div>
          )}
          <JPGPreviewWithFetch
            ref={ref}
            previewLoading={previewLoading}
            setPreviewLoading={setPreviewLoading}
            streamId={vpaStreamId}
          />
        </div>
        <div className={classes.thresholdsColumn}>
          <FireThresholds settings={settings} setSettings={setSettings} flare />
        </div>
      </div>
      <Divider className={classes.divider} />
      <div className={classes.secondRow}>
        <FlareAlarmingModeSettings
          eventTimers={eventTimers}
          setEventTimers={setEventTimers}
          className={classes.alarmingSettings}
        />
        <ReportingModeSettings
          eventTimers={eventTimers}
          setEventTimers={setEventTimers}
        />
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  thresholdsColumn: {
    maxWidth: '400px',
    minWidth: '300px',
    flexGrow: 1,
  },
  previewWithZones: {
    flex: 2,
    maxWidth: '1400px',
    marginBottom: theme.spacing(10),
  },
  firstRow: {
    display: 'flex',
    marginTop: theme.spacing(3),
  },
  secondRow: {
    display: 'flex',
  },
  alarmingSettings: {
    marginRight: theme.spacing(6),
  },
  divider: {
    marginBottom: theme.spacing(4),
    backgroundColor: theme.palette.grey[400],
    maxWidth: '1800px',
  },
  konvaShape: {
    position: 'absolute',
    zIndex: 10,
    width: 'min-content',
  },
  intro: {
    marginBottom: theme.spacing(1),
  },
}));
