import { Dispatch, SetStateAction, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { ActionsBar } from 'components/ActionsBar/ActionsBar';
import SubmitButton from 'components/Button/SubmitButton';
import ButtonOutlined from 'components/Button/ButtonOutlined';
import { useTranslation } from 'react-i18next';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import { useAuth } from 'context/providers/AuthProvider';
import { useStreamData } from 'context/providers/StreamDataProvider';
import { useParams } from 'react-router-dom';
import { AlertSeverity } from 'components/Alert/Toast';
import {
  changeAnalyticsSettings,
  changeEventTimers,
  sendTestEvents,
} from 'api/analyticsApi';
import { useAnalyticsSettings } from 'context/providers/AnalyticsSettingsProvider';
import { convertToApiValues } from 'api/helpers';

type AnalyticsActionsProps = {
  setFeedback: Dispatch<
    SetStateAction<{
      open: boolean;
      status: AlertSeverity;
      msg: string;
    }>
  >;
};

export const AnalyticsActions = ({ setFeedback }: AnalyticsActionsProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isGuest } = useAuth();
  const { selectedAnalytics } = useStreamData();
  const { streamId } = useParams<{ streamId: string }>();
  const { analyticsSettings, setAnalyticsSettings, eventTimers } =
    useAnalyticsSettings();
  const [loading, setLoading] = useState({ save: false, test: false });

  const handleSave = () => {
    if (!streamId || !selectedAnalytics || !analyticsSettings || !eventTimers)
      return;

    setFeedback({ open: false, status: AlertSeverity.SUCCESS, msg: '' });
    setLoading({ ...loading, save: true });

    const convertedTimers = convertToApiValues(eventTimers);
    console.log('eventTimers', eventTimers);
    console.log('convertedTimers', convertedTimers);

    Promise.all([
      changeAnalyticsSettings(streamId, analyticsSettings),
      changeEventTimers(streamId, selectedAnalytics, convertedTimers),
    ])
      .then((responses) => {
        const updatedSettings = responses[0];
        setAnalyticsSettings(updatedSettings);

        setFeedback({
          open: true,
          status: AlertSeverity.SUCCESS,
          msg: 'settings-change-success',
        });
        setLoading({ ...loading, save: false });
      })
      .catch((error) => {
        setLoading({ ...loading, save: false });
        setFeedback({
          open: true,
          status: AlertSeverity.ERROR,
          msg: error.response.data.description
            ? error.response.data.description
            : 'general-error',
        });
      });
  };

  const handleSendTestEvents = () => {
    if (!streamId || !selectedAnalytics) return;

    setFeedback({ open: false, status: AlertSeverity.SUCCESS, msg: '' });
    setLoading({ ...loading, test: true });

    sendTestEvents(streamId, selectedAnalytics)
      .then(() => {
        setLoading({ ...loading, test: false });
        setFeedback({
          open: true,
          status: AlertSeverity.SUCCESS,
          msg: 'test-event-success',
        });
      })
      .catch((error) => {
        setLoading({ ...loading, test: false });
        setFeedback({
          open: true,
          status: AlertSeverity.ERROR,
          msg: error.response.data.description
            ? error.response.data.description
            : 'general-error',
        });
      });
  };

  return (
    <ActionsBar>
      <ButtonOutlined
        onClick={handleSendTestEvents}
        loading={loading.test}
        startIcon={<AssessmentOutlinedIcon />}
        disabled={isGuest}
        className={classes.testEventsButton}
        id="test-events"
      >
        {t(`stream-settings.send-test-events`)}
      </ButtonOutlined>
      <SubmitButton
        onClick={handleSave}
        isLoading={loading.save}
        disabled={loading.save || isGuest}
        className={classes.submitButton}
        id="save-analytics"
      >
        {t('save')}
      </SubmitButton>
    </ActionsBar>
  );
};

const useStyles = makeStyles((theme) => ({
  submitButton: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(2),

    '&.Mui-disabled': {
      backgroundColor: 'rgba(0, 0, 0, 0.28)',
      color: theme.palette.grey[300],
    },
  },
  testEventsButton: {
    '&.Mui-disabled': {
      color: theme.palette.grey[300],
      border: `1px solid ${theme.palette.grey[300]}`,
    },
  },
}));
