import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import { makeStyles, MenuItem, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import SliderContinous from 'components/Slider/SliderContinuous';
import { EventTimers } from 'types/analyticsTypes';
import CheckboxWithLabel from 'components/Checkbox/CheckboxWithLabel';
import clsx from 'clsx';
import Select, { SelectChangeEvent } from '@mui/material/Select';

type AlarmingModeSettingsProps = {
  eventTimers: EventTimers;
  setEventTimers: Dispatch<SetStateAction<EventTimers>>;
  className?: string;
};

export const FlareAlarmingModeSettings = ({
                                            eventTimers,
                                            setEventTimers,
                                            className,
                                          }: AlarmingModeSettingsProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [delay, setDelay] = useState(eventTimers.delayTimeSeconds);
  const [cooldown, setCooldown] = useState(
    eventTimers.alarmingSettings.nextEventCooldownSeconds,
  );
  const [fireArea, setFireArea] = useState(eventTimers.alarmingSettings.fireArea);
  const [fireCollectionTime, setFireCollectionTime] = useState(eventTimers.alarmingSettings.fireCollectionTime);
  const [fireAlarmingMode, setFireAlarmingMode] = useState(eventTimers.alarmingSettings.fireAlarmingMode);

  const setAlarmingSettings = (value: number, name: string) => {
    setEventTimers({
      ...eventTimers,
      alarmingSettings: {
        ...eventTimers.alarmingSettings,
        [name]: value,
      },
    });
  };

  const setAlarmingSettingsEnabled = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    setEventTimers({
      ...eventTimers,
      alarmingSettings: {
        ...eventTimers.alarmingSettings,
        isEnabled: checked,
      },
    });
  };

  const commitDelayChange = (
    event: ChangeEvent<unknown> | undefined,
    value: number | number[],
  ) => {
    if (typeof value !== 'number') return;

    setEventTimers({ ...eventTimers, delayTimeSeconds: value });
  };

  const commitCooldownChange = (
    event: ChangeEvent<unknown> | undefined,
    value: number | number[],
  ) => {
    if (typeof value !== 'number') return;

    setAlarmingSettings(value, 'nextEventCooldownSeconds');
  };

  const handleAlarmingModeChange = (event: SelectChangeEvent) => {
    setFireAlarmingMode(event.target.value as string);

    setEventTimers({
      ...eventTimers,
      alarmingSettings: {
        ...eventTimers.alarmingSettings,
        fireAlarmingMode: event.target.value as string,
      },
    });
  };

  const commitfireAreaChange = (
    event: ChangeEvent<unknown> | undefined,
    value: number | number[],
  ) => {
    if (typeof value !== 'number') return;
    setAlarmingSettings(value, 'fireArea')
  }

  const commitfireCollectionTimeChange = (
    event: ChangeEvent<unknown> | undefined,
    value: number | number[],
  ) => {
    if (typeof value !== 'number') return;
    setAlarmingSettings(value, 'fireCollectionTime')
  }

  return (
    <div className={clsx(className, classes.alarmingSettingsContainer)}>
      <Typography className={classes.heading} align="center" variant="h3">
        {t('stream-settings.alarming-mode-settings')}
      </Typography>
      <Typography variant="body2" className={classes.margin}>
        {t('stream-settings.alarming-mode-description')}
      </Typography>
      <CheckboxWithLabel
        label={t('stream-settings.enable-alarming')}
        checked={eventTimers.alarmingSettings.isEnabled}
        onChange={setAlarmingSettingsEnabled}
        className={classes.margin}
        labelClassName={classes.checkboxLabel}
        id="enable-alarming-checkbox"
      />

      <Typography className={classes.heading} align="center" variant="h3">
        {t('stream-settings.smoke-area')}
      </Typography>

      <SliderContinous
        name="delayTimeSeconds"
        value={delay}
        max={30}
        setValue={setDelay}
        label={t('stream-settings.delay')}
        customValueLabel
        description={t('stream-settings.delay-description')}
        onChangeCommitted={commitDelayChange}
        id="delay-slider-alarming"
      />

      <SliderContinous
        name="nextEventCooldownSeconds"
        value={cooldown}
        max={30}
        setValue={setCooldown}
        label={t('stream-settings.cooldown')}
        customValueLabel
        description={t('stream-settings.cooldown-description')}
        onChangeCommitted={commitCooldownChange}
        id="cooldown-slider-alarming"
      />

      <Typography className={classes.heading} align="center" variant="h3">
        {t('stream-settings.fire-area')}
      </Typography>

      <SliderContinous
        name="fireArea"
        value={fireArea}
        max={100}
        min={1}
        defaultValue={50}
        setValue={setFireArea}
        label={t('stream-settings.fireArea')}
        customValueLabel
        description={t('stream-settings.fireArea-description')}
        onChangeCommitted={commitfireAreaChange}
        id="fireArea-slider-alarming"
      />

      <SliderContinous
        name="fireCollectionTime"
        value={fireCollectionTime}
        max={600}
        min={1}
        defaultValue={10}
        setValue={setFireCollectionTime}
        label={t('stream-settings.fireCollectionTime')}
        customValueLabel
        description={t('stream-settings.fireCollectionTime-description')}
        onChangeCommitted={commitfireCollectionTimeChange}
        id="fireCollectionTime-slider-alarming"
      />
      <Typography className={classes.heading} align="center" variant="h3">
        {t('stream-settings.alarming-mode-type')}
      </Typography>
      <Select
        label={t('stream-settings.alarmingMode')}
        id={'alarmingMode-select-alarming'}
        value={fireAlarmingMode}
        onChange={handleAlarmingModeChange}
        variant={"filled"}
      >
        <MenuItem value={'LARGER_THAN_AREA'}>{t('stream-settings.alarming-mode-larger')}</MenuItem>
        <MenuItem value={'SMALLER_THAN_AREA'}>{t('stream-settings.alarming-mode-smaller')}</MenuItem>

      </Select>

    </div>
  );
};
const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: theme.spacing(2),
  },
  checkboxLabel: {
    fontWeight: 500,
  },
  margin: {
    marginBottom: theme.spacing(1),
  },
  alarmingSettingsContainer: {
    maxWidth: '450px',
    minWidth: '300px',
    flexGrow: 1,
  },
}));
